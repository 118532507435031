/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2023-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Box } from '@mui/material'
import { formatISO } from 'date-fns'
import noop from 'lodash'
import { makeValidate } from 'mui-rff'
import React from 'react'
import * as Yup from 'yup'

import Certificates from 'components/Certificates'
import EditCoursePortalStaffOnlyFormFields from 'components/forms/EditCoursePortalStaffOnlyFormFields'
import { currentPortal } from 'components/materialTheme/commonTheme'
import FormModal from 'components/modals/FormModal'
import { useCourse } from 'hooks/data/courses'
import useOnSubmit from 'hooks/useOnSubmit'
import useUrls from 'hooks/useUrls'
import { validateCMECredits } from 'utils/validation'

const schema = Yup.object().shape({
    purchaseURLForNonDRGMembers: Yup.string().url(),
    expirationDate: Yup.date().nullable().typeError(gettext('Invalid Date Format')),
    certificateThreshold: Yup.number().min(0).max(100),
    cmeCredits: validateCMECredits.fields.cmeCredits
})
const validate = makeValidate(schema)

export default function EditCoursePropertiesModal({
    courseId,
    onHide,
    triggerMutation = noop
}: {
    courseId: number
    onHide: (...args: any[]) => any
    triggerMutation?: (...args: any) => void
}) {
    const { data: course } = useCourse(courseId)
    const urls = useUrls()

    const { onSubmit } = useOnSubmit({
        detailFn: urls.courseDetail,
        id: courseId,
        fileFields: ['thumbnail'],
        defaultRedirect: false
    })

    if (!course) {
        return null
    }

    return (
        <FormModal
            title={gettext('Edit course properties')}
            subtitle={course.title}
            open
            onReject={onHide}
            onSubmit={(values, form) => {
                onSubmit(
                    {
                        ...values,
                        expirationDate:
                            values.expirationDate instanceof Date
                                ? formatISO(values.expirationDate, { representation: 'date' })
                                : values.expirationDate
                    },
                    form
                )
                triggerMutation({ id: courseId, changes: values })
                onHide()
            }}
            validate={validate}
            initialValues={{
                ...course
            }}
            maxWidth="md"
        >
            <React.Fragment>
                {course.actions.editCourseProperties && <EditCoursePortalStaffOnlyFormFields />}
                {course.actions.manageCertificates && currentPortal() === 'drg' && (
                    <Box mt={2}>
                        <Certificates courseId={courseId} />
                    </Box>
                )}
            </React.Fragment>
        </FormModal>
    )
}
