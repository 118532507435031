/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Box, MenuItem, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Checkboxes, Select } from 'mui-rff'
import React from 'react'
import { FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

import CMECreditsInput from 'components/forms/CMECreditsInput'
import ExpirationDateInput from 'components/forms/ExpirationDateInput'
import { currentPortal } from 'components/materialTheme/commonTheme'

import TextFieldWrapper from './TextFieldWrapper'

const useStyles = makeStyles(() => ({
    singleCheckbox: {
        '& label': {
            marginBottom: 0
        },
        '& p': {
            marginTop: 0
        }
    }
}))

export function updatePurchaseURLParameters(form, productNumber, series) {
    if (!productNumber || !series) {
        form.change('purchaseURLParameters', '')
        return
    }
    const params = new URLSearchParams({ reihe: series, artikel_nr: productNumber })
    form.change('purchaseURLParameters', params.toString())
}

function FieldListener({ fieldName }: { fieldName: string }) {
    return (
        <FormSpy subscription={{}}>
            {({ form }) => (
                <OnChange name={fieldName}>
                    {value => {
                        if (fieldName === 'open' && value) {
                            form.change('isVisibleForAll', true)
                            form.change('priceValue', 0)
                            form.change('priceSecondaryValue', 0)
                            form.change('examinationCourse', false)
                        }
                        if (fieldName === 'isVisibleForAll' && !value) {
                            form.change('open', false)
                        }
                        if (fieldName === 'isVisibleForAll' && value) {
                            form.change('examinationCourse', false)
                        }
                        if (fieldName === 'priceValue' && value > 0) {
                            form.change('open', false)
                        }
                        if (fieldName === 'examinationCourse' && value) {
                            form.change('showCorrectChoices', false)
                            form.change('open', false)
                            form.change('isVisibleForAll', false)
                        }
                        if (fieldName === 'showCorrectChoices' && value) {
                            form.change('examinationCourse', false)
                        }
                        if (fieldName === 'series') {
                            updatePurchaseURLParameters(form, form.getFieldState('productNumber').value, value)
                        }
                        if (fieldName === 'productNumber') {
                            updatePurchaseURLParameters(form, value, form.getFieldState('series').value)
                        }
                        if (fieldName === 'certificateVideoThreshold' && value === '') {
                            form.change('certificateVideoThreshold', null)
                        }
                    }}
                </OnChange>
            )}
        </FormSpy>
    )
}

export default function EditCoursesDRGOnlyFormFields() {
    const classes = useStyles()
    const menuProps = {
        variant: 'menu',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        transformOrigin: { vertical: 'top', horizontal: 'center' }
    } as const
    const theme = useTheme()

    const currencyChoices = [
        { id: 'EUR', name: gettext('EUR (Euro)') },
        { id: 'USD', name: gettext('USD (US Dollar)') }
    ]

    return (
        <React.Fragment>
            <FieldListener fieldName="open" />
            <FieldListener fieldName="priceValue" />
            <FieldListener fieldName="isVisibleForAll" />
            <FieldListener fieldName="examinationCourse" />
            <FieldListener fieldName="showCorrectChoices" />
            <FieldListener fieldName="series" />
            <FieldListener fieldName="productNumber" />
            <FieldListener fieldName="certificateVideoThreshold" />
            {currentPortal() === 'drg' && (
                <Typography variant="h2">{gettext('To be completed by the DRG office only!')}</Typography>
            )}
            <Box display="flex" flexDirection="column">
                <Checkboxes
                    name="examinationCourse"
                    data={{
                        label: gettext(
                            'Examination course (controls whether this is a non-resettable examination course)'
                        ),
                        value: true
                    }}
                    formControlProps={{ className: classes.singleCheckbox }}
                />
                <Checkboxes
                    name="showCorrectChoices"
                    data={{
                        label: gettext(
                            'Colour coding (if selected, correct answers are colour coded. Is always deselected for examination courses)'
                        ),
                        value: true
                    }}
                    formControlProps={{ className: classes.singleCheckbox }}
                />
                {currentPortal() === 'drg' && (
                    <React.Fragment>
                        <Checkboxes
                            name="isVisibleForAll"
                            data={{
                                label: gettext('Visible for all / Visible4All'),
                                value: true
                            }}
                            formControlProps={{ className: classes.singleCheckbox }}
                        />
                        <Checkboxes
                            name="open"
                            data={{
                                label: gettext('Open (can be opened without login)'),
                                value: true
                            }}
                            formControlProps={{ className: classes.singleCheckbox }}
                        />
                    </React.Fragment>
                )}
                {currentPortal() === 'drg' && (
                    <Box display="flex" flexDirection="column" gap={theme.spacing(2)} mt={2}>
                        <Box display="flex" gap={theme.spacing(2)}>
                            <TextFieldWrapper
                                id="priceValue"
                                name="priceValue"
                                type="number"
                                inputProps={{ min: 0 }}
                                label={gettext('Price')}
                                variant="outlined"
                                sx={{ flexGrow: 1 }}
                            />
                            <TextFieldWrapper
                                id="priceSecondaryValue"
                                name="priceSecondaryValue"
                                type="number"
                                inputProps={{ min: 0 }}
                                label={gettext('Non-Member Price')}
                                variant="outlined"
                                sx={{ flexGrow: 1 }}
                            />

                            <Select
                                name="priceCurrency"
                                variant="outlined"
                                label={gettext('Currency')}
                                MenuProps={menuProps}
                                inputProps={{ id: 'priceCurrency' }}
                            >
                                {currencyChoices.map(c => (
                                    <MenuItem key={c.id} value={c.id}>
                                        {c.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box display="flex" gap={theme.spacing(2)}>
                            <TextFieldWrapper
                                id="series"
                                name="series"
                                inputProps={{ min: 0 }}
                                label={gettext('Series')}
                                type="number"
                                variant="outlined"
                            />
                            <TextFieldWrapper
                                id="productNumber"
                                name="productNumber"
                                label={gettext('Product-No.')}
                                placeholder="CONRAD-123"
                                type="text"
                                variant="outlined"
                            />
                        </Box>
                        <TextFieldWrapper
                            id="purchaseURLForNonDRGMembers"
                            name="purchaseURLForNonDRGMembers"
                            label={gettext('Purchase URL for non DRG members')}
                            type="text"
                            variant="outlined"
                            helperText={gettext(
                                'If set, the user can buy this course via this URL without a DRG membership'
                            )}
                        />
                        <TextFieldWrapper
                            name="certificateThreshold"
                            label={gettext(
                                'Necessary percentage of correct answers for obtaining a certificate (default is 0).'
                            )}
                            type="number"
                            variant="outlined"
                            inputProps={{ min: 0, max: 100 }}
                            data-testid="certificateThreshold"
                        />

                        <TextFieldWrapper
                            name="certificateVideoThreshold"
                            label={gettext(
                                'Necessary number of viewed video minutes for obtaining a certificate (default is empty)'
                            )}
                            type="number"
                            variant="outlined"
                            inputProps={{ min: 0 }}
                            data-testid="certificateVideoThreshold"
                        />

                        <ExpirationDateInput />

                        <TextFieldWrapper
                            id="accessInformation"
                            name="accessInformation"
                            label={gettext('Access information')}
                            type="text"
                            variant="outlined"
                            helperText={gettext('E.g. This course is available for DRG members until 31.12.2023')}
                        />

                        <CMECreditsInput />
                    </Box>
                )}
            </Box>
        </React.Fragment>
    )
}
